<template>
    <el-dialog :title="title" :visible.sync="isShow" width="420px" @opened="onOpened" :before-close="onClose">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="100px">
            <el-form-item prop="name" label="员工姓名">
                <el-input v-model.trim="formData.name" :maxlength="32" show-word-limit placeholder="请输入员工姓名" />
            </el-form-item>
            <el-form-item prop="telNo" label="手机号">
                <el-input v-model.trim="formData.telNo" placeholder="请输入手机号" :maxlength="11" show-word-limit />
            </el-form-item>
            <el-form-item prop="roleIds" label="账号角色">
                <el-select v-model="formData.roleIds" multiple filterable placeholder="请选择">
                    <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item prop="groupId" label="所在组织">
                <el-select v-model="formData.groupId" clearable filterable placeholder="请选择">
                    <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item prop="projectNos" label="关联项目">
                <el-select v-model="formData.projectNos" multiple collapse-tags placeholder="请选择">
                    <el-option
                        v-for="item in projectList"
                        :key="item.projectNo"
                        :label="item.projectName"
                        :value="item.projectNo"
                    />
                </el-select>
            </el-form-item>
            <el-form-item prop="sex" label="性别">
                <el-select v-model="formData.sex">
                    <el-option label="男" :value="1"></el-option>
                    <el-option label="女" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="!formData.id" prop="password" label="初始密码">
                <el-input v-model.trim="formData.password" :maxlength="20" placeholder="请输入" />
            </el-form-item>

            <el-form-item prop="nickname" label="直播间昵称">
                <el-input v-model.trim="formData.nickname" :maxlength="32" show-word-limit placeholder="请输入" />
            </el-form-item>
            <el-form-item prop="remark" label="备注">
                <el-input
                    v-model="formData.remark"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    placeholder="请输入"
                />
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { addStaffAccount, modStaffInfo, delGroupMemer } from '@/js/api/staffApi.js'
import { saveProjectStaff } from '@/js/api/commonApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { v4 as uuidv4 } from 'uuid'

export default {
    props: ['isShow', 'activeItem', 'groupList', 'roleList', 'projectList'],
    data() {
        const telNoValidate = (rule, value, callback) => {
            if (/^1[3456789]\d{9}$/.test(value)) {
                callback()
            } else {
                callback('请输入正确的手机号')
            }
        }

        return {
            title: '',
            formData: null,
            rules: {
                telNo: [
                    { required: true, message: '请输入手机号', trigger: 'change' },
                    { validator: telNoValidate, trigger: 'change' },
                ],
                name: [{ required: true, message: '请输入员工姓名', trigger: 'change' }],
                password: [{ required: true, message: '请输入密码', trigger: 'change' }],
                roleIds: [{ required: true, message: '请选择账号角色', trigger: 'change' }],
                sex: [{ required: true, message: '请选择员工性别', trigger: 'change' }],
                projectNos: [{ required: true, message: '请选择关联项目', trigger: 'change' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.title = '新建账号'
            let formData = {
                telNo: '',
                name: '',
                groupId: '',
                nickname: '',
                roleIds: [],
                sex: '',
                remark: '',
                projectNos: [],
            }
            if (this.activeItem) {
                this.title = `${this.activeItem.alias} - 编辑`
                formData = { ...formData, ...this.activeItem }
                formData.groupId = formData.group ? formData.group.id : ''
                if (formData.roles && formData.roles.length !== 0) {
                    formData.roleIds = formData.roles.map((i) => i.id)
                }
            } else {
                formData.password = uuidv4().slice(0, 8)
            }
            this.formData = formData
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onSubmit() {
            this.$refs.formData.validate(async (valid) => {
                if (valid) {
                    const _roles = this.formData.roleIds.map((i) => {
                        return { id: i }
                    })
                    let _params = { ...this.formData, roles: _roles }
                    _params.group = _params.groupId ? { id: _params.groupId } : null
                    let res = null
                    _params = filterQueryEmpty(_params)

                    if (this.formData.id) {
                        res = await modStaffInfo(_params)
                    } else {
                        res = await addStaffAccount(_params)
                    }
                    if (!_params.group && this.activeItem && this.activeItem.group) {
                        await delGroupMemer({ staffId: _params.id, groupId: this.activeItem.group.id, type: 'member' })
                    }

                    await saveProjectStaff({ staffId: res.data.id, projectNos: this.formData.projectNos })
                    this.$message.success('编辑成功')
                    this.$emit('onClose', true)
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-select,
.el-input-number {
    width: 100%;
}
</style>
