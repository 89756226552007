<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <el-select v-model="projectNo" filterable placeholder="请选择">
                            <el-option
                                v-for="item in projectList"
                                :key="item.projectNo"
                                :label="item.projectName"
                                :value="item.projectNo"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入"
                            v-model.trim="queryTypeValue"
                            clearable
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select class="query_type" v-model="queryType" slot="prepend">
                                <el-option label="手机号" value="telNo"></el-option>
                                <el-option label="用户名" value="name"></el-option>
                            </el-select>
                        </el-input>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">组织</span>
                    <span class="condition_content">
                        <el-select v-model="groupId" clearable filterable placeholder="请选择">
                            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">角色</span>
                    <span class="condition_content">
                        <el-select v-model="roleId" clearable filterable placeholder="请选择">
                            <el-option
                                v-for="item in roleList"
                                :key="item.id"
                                :label="item.roleName"
                                :value="item.id"
                            />
                        </el-select>
                    </span>
                </div>
                <!-- <div class="condition_item">
                    <span class="condition_label">状态</span>
                    <span class="condition_content">
                        <el-select v-model="state" clearable filterable placeholder="请选择">
                            <el-option label="正常" :value="0" />
                            <el-option label="禁用" :value="1" />
                        </el-select>
                    </span>
                </div> -->
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button type="primary" @click="onPaginationChange('page', 1)">查询</el-button>
                <el-button class="float_left_btn" type="primary" plain @click="onShowEditStaff(null)">
                    新建员工
                </el-button>
                <el-button class="float_left_btn" plain @click="onShowGroup(null)">组织管理</el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="name" label="用户名" width="120" />
            <el-table-column align="center" prop="telNo" label="登陆账号" width="120" />
            <el-table-column align="center" label="账号角色" width="120">
                <template slot-scope="scope">
                    <div class="role_list" v-if="scope.row.roles">
                        <span :class="{ admin: i.roleNo == 'admin' }" v-for="i in scope.row.roles" :key="i.roleNo">
                            {{ i.roleName }}
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="所在组织" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.group">{{ scope.row.group.name }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="关联项目" width="120">
                <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.projectInfo" placement="right-start" effect="light">
                        <div class="project_list" slot="content">
                            <div v-for="(p, i) in scope.row.projectInfo.data" :key="i">
                                <span class="projectName">{{ p.projectName || '-' }}</span>
                                <el-popover placement="left-start" v-if="p.qrcodeUrl">
                                    <el-link type="success" slot="reference">接量码已配置</el-link>
                                    <el-image style="width: 280px" :src="p.qrcodeUrl" />
                                </el-popover>
                                <el-tag size="mini" effect="dark" type="info" v-else>接量码未配置</el-tag>
                            </div>
                        </div>

                        <div class="project_info">
                            <div>
                                关联 <el-link>{{ scope.row.projectInfo.total }}</el-link> 个项目
                            </div>
                            <div>
                                <el-link :type="scope.row.projectInfo.receiveNum === 0 ? '' : 'danger'">
                                    {{ scope.row.projectInfo.receiveNum }}
                                </el-link>
                                个已配活码
                            </div>
                        </div>
                    </el-tooltip>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="直播间昵称" prop="nickname" width="120" />
            <el-table-column header-align="center" label="备注" prop="remark" />
            <el-table-column align="center" label="状态" prop="addTime" width="120">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.state === 0" type="success" effect="dark">正常</el-tag>
                    <el-tag v-else-if="scope.row.state === 1" type="danger" effect="dark">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" prop="addTime" width="96" />
            <el-table-column align="center" width="80" label="操作" fixed="right">
                <template slot-scope="scope">
                    <div class="mini_link">
                        <el-link type="primary" @click="onShowEditStaff(scope.row)">编辑信息</el-link>
                        <el-link type="warning" @click="onEditPassword(scope.row)">修改密码</el-link>

                        <el-popconfirm
                            v-if="scope.row.state === 0"
                            title="确定禁用账号吗？"
                            @confirm="onModStaffState(scope.row, 1)"
                        >
                            <el-link slot="reference" type="danger">禁用账号</el-link>
                        </el-popconfirm>
                        <template v-else>
                            <el-link type="warning" @click="onModStaffState(scope.row, 0)">恢复账号</el-link>
                            <el-popconfirm title="确定删除吗？" @confirm="onDelStaff(scope.row)">
                                <el-link slot="reference" type="danger">删除账号</el-link>
                            </el-popconfirm>
                        </template>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, sizes"
            :total="total"
        />

        <Group :isShow="isShowGroup" @onClose="onCloseGroup" />

        <EditStaff
            :projectList="projectList"
            :groupList="groupList"
            :roleList="roleList"
            :isShow="isShowEditStaff"
            :activeItem="activeItem"
            @onClose="onCloseEditStaff"
        />
    </div>
</template>
<script>
import Group from './components/staffList/Group.vue'
import EditStaff from './components/staffList/EditStaff.vue'
import { getProjectList, getProjectStaff } from '@/js/api/commonApi.js'
import { getStaffDetailList, getStaffGroupList, modStaffInfo, delStaff, getStaffRoleList } from '@/js/api/staffApi.js'
import { getStaffQrcode } from '@/js/api/staffApi.js'
import { filterQueryEmpty } from '@/js/utils.js'

export default {
    components: { Group, EditStaff },
    data() {
        return {
            projectNo: '',
            projectList: [],
            queryTypeValue: '',
            queryType: 'name',
            // 组织
            groupList: [],
            groupId: '',
            // 角色
            roleList: [],
            roleId: '',
            state: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowEditStaff: false,
            isShowGroup: false,
        }
    },
    created() {
        this.getStaffDetailListApi()
        this.getStaffGroupListApi()
        this.getStaffRoleListApi()
    },
    methods: {
        onReset() {
            this.projectNo = ''
            this.queryTypeValue = ''
            this.queryType = 'name'
            this.groupId = ''
            this.roleId = ''
            this.state = ''
            this.onPaginationChange('page', 1)
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getStaffDetailListApi)
        },

        onModStaffState(item, state) {
            modStaffInfo({
                id: item.id,
                state: state,
            }).then(() => {
                this.$message.success('修改成功')
                this.getStaffDetailListApi()
            })
        },
        onShowEditStaff(item) {
            this.activeItem = item
            this.isShowEditStaff = true
        },
        onCloseEditStaff(state) {
            this.activeItem = null
            this.isShowEditStaff = false
            if (state) this.getStaffDetailListApi()
        },

        // 组织管理
        onShowGroup() {
            this.isShowGroup = true
        },
        onCloseGroup(state) {
            this.isShowGroup = false
            if (state) {
                this.getStaffDetailListApi()
                this.getStaffGroupListApi()
            }
        },

        // 密码
        onEditPassword(item) {
            this.$prompt(`${item.name} - 密码修改`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator: (value) => {
                    if (!value) return '请输入密码'
                    if (value.length < 6) return '密码长度大于6'
                    return true
                },
            })
                .then(({ value }) => {
                    modStaffInfo({ id: item.id, password: value.trim() }).then(() => {
                        this.$message.success('修改成功')
                        this.getStaffDetailListApi()
                    })
                })
                .catch(() => {})
        },
        // 删除账号
        onDelStaff(item) {
            delStaff({ id: item.id }).then(() => {
                this.$message.success('删除成功')
                this.getStaffDetailListApi()
            })
        },

        async getStaffDetailListApi() {
            if (this.projectList.length === 0) {
                const res = await getProjectList({ orderBy: '+id' })
                this.projectList = res.data
            }
            const _projectMap = {}
            this.projectList.forEach((i) => {
                _projectMap[i.projectNo] = i.projectName
            })

            let params = {
                page: this.page,
                size: this.pageSize,
                showPage: true,
                state: this.state,
                projectNo: this.projectNo,
            }
            if (this.groupId) params.groupIds = [this.groupId]
            if (this.roleId) params.roleIds = [this.roleId]
            params[this.queryType] = this.queryTypeValue
            params = filterQueryEmpty(params)

            const res1 = await getStaffDetailList(params)
            if (res1.data.list.length !== 0) {
                const _ids = res1.data.list.map((i) => i.id)
                const res2 = await Promise.all([
                    getProjectStaff({ staffIds: _ids }),
                    getStaffQrcode({ staffIds: _ids }),
                ])
                // 员工关联项目， 以及是否配置接量码
                const _staffProjectMap = {}
                res2[0].data.forEach((item) => {
                    _staffProjectMap[`${item.staffId}-${item.projectNo}`] = { qrcode: false }
                })
                res2[1].data.forEach((i) => {
                    if (_staffProjectMap[`${i.staffId}-${i.projectNo}`]) {
                        _staffProjectMap[`${i.staffId}-${i.projectNo}`]['qrcode'] = i.qrcodeUrl
                    }
                })
                const _projectInfo = {}
                Object.keys(_staffProjectMap).forEach((k) => {
                    const [staffId, projectNo] = k.split('-')
                    if (!_projectInfo[staffId]) _projectInfo[staffId] = []
                    _projectInfo[staffId].push({
                        projectNo: projectNo,
                        projectName: _projectMap[projectNo],
                        qrcodeUrl: _staffProjectMap[k]['qrcode'],
                    })
                })
                res1.data.list.forEach((i) => {
                    const _list = _projectInfo[i.id] || []
                    let _receiveNum = 0
                    _list.forEach((i) => {
                        if (i.qrcodeUrl) _receiveNum += 1
                    })
                    i.projectInfo = {
                        data: _list,
                        total: _list.length,
                        receiveNum: _receiveNum,
                    }
                    i.projectNos = i.projectInfo.data.map((i) => i.projectNo)
                })
            }
            this.tableData = res1.data.list
            this.total = res1.data.total
            this.page = res1.data.pageNum
        },
        getStaffGroupListApi() {
            getStaffGroupList({ orderBy: '+seq' }).then((res) => {
                this.groupList = res.data
            })
        },
        getStaffRoleListApi() {
            getStaffRoleList({}).then((res) => {
                this.roleList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.role_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .admin {
        color: #3dc202;
        font-weight: 600;
    }
    span {
        margin: 2px 4px;
    }
}

.project_info {
    cursor: pointer;
}

.project_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
        margin-bottom: 12px;
        display: flex;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .projectName {
        min-width: 60px;
        margin-right: 6px;
    }
}
</style>
