<template>
    <el-dialog :title="title" :visible.sync="isShow" width="480px" @opened="onOpened" :before-close="onClose">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="90px">
            <el-form-item prop="seq" label="排序">
                <el-input-number v-model.trim="formData.seq" :controls="false" :precision="0" :min="1" />
            </el-form-item>
            <el-form-item prop="name" label="名称">
                <el-input v-model.trim="formData.name" :maxlength="64" show-word-limit placeholder="请输入名称" />
            </el-form-item>
            <el-form-item prop="trade" label="订单提醒">
                <el-input
                    @change="onBotKeyChange('trade')"
                    v-model.trim="formData.trade"
                    show-word-limit
                    placeholder="请粘贴订单提醒"
                    clearable
                />
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { saveStaffGroup } from '@/js/api/staffApi.js'
import { filterQueryEmpty } from '@/js/utils.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',
            formData: null,
            rules: {
                name: [{ required: true, message: '请输入名称', trigger: 'change' }],
                seq: [{ required: true, message: '请输入序号', trigger: 'change' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.title = '新建组织'
            let formData = {
                name: '',
                seq: '',
            }
            if (this.activeItem) {
                this.title = `${this.activeItem.name} - 编辑`
                formData = { ...formData, ...this.activeItem }
                const _key = formData.botKey ? JSON.parse(formData.botKey || '') : {}
                formData.trade = _key.trade
            }
            this.formData = formData
        },
        onClose() {
            this.title = ''
            this.formData = null
            this.$emit('onClose')
        },
        onBotKeyChange(key) {
            this.formData[key] = this.formData[key].replace('https://qyapi.weixin.qq.com/cgi-bin/webhook/send?key=', '')
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        id: this.formData.id,
                        name: this.formData.name,
                        seq: this.formData.seq,
                    }
                    let _val = { trade: this.formData.trade }
                    _val = filterQueryEmpty(_val)
                    params.botKey = JSON.stringify(_val)
                    params = filterQueryEmpty(params)
                    saveStaffGroup(params).then(() => {
                        this.$message.success('编辑成功')
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-input-number {
    width: 100%;
}
</style>
