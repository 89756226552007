<template>
    <el-dialog :title="title" :visible.sync="isShow" width="420px" @opened="onOpened" :before-close="onClose">
        <el-form label-width="100px">
            <el-form-item label="添加管理员">
                <div class="select">
                    <StaffSelect ref="staffSelect" :clearable="true" @change="(val) => (staffId = val)" />
                    <el-button type="primary" @click="onSubmit">设置为管理员</el-button>
                </div>
            </el-form-item>
            <el-form-item label="当前管理员">
                <div class="admin">
                    <el-tag
                        v-for="i in adminList"
                        :key="i.id"
                        closable
                        type="info"
                        effect="plain"
                        @close="onDelAdmin(i)"
                    >
                        {{ i.staff.name }}
                    </el-tag>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { saveGroupMemer, getGroupMemerList, delGroupMemer } from '@/js/api/staffApi.js'
import StaffSelect from '@/views/components/StaffSelect'

export default {
    components: { StaffSelect },
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',

            staffId: '',

            adminList: [],
        }
    },
    methods: {
        onOpened() {
            this.title = '编辑管理员'
            if (this.activeItem) {
                this.title = `${this.activeItem.name} - 编辑管理员`
            }
            this.updateState = false
            this.getGroupMemerListApi()
        },
        onClose() {
            this.title = ''
            this.staffId = ''
            this.$emit('onClose', this.updateState)
        },
        onSubmit() {
            if (!this.staffId) {
                return this.$message.warning('请选择设置管理员的账号')
            }
            saveGroupMemer({ staffId: this.staffId, groupId: this.activeItem.id, type: 'manager' }).then(() => {
                this.$refs.staffSelect.onClear()
                this.$message.success('编辑成功')
                this.updateState = true
                this.getGroupMemerListApi()
            })
        },
        onDelAdmin(item) {
            this.updateState = true
            delGroupMemer({ staffId: item.staff.id, groupId: this.activeItem.id, type: 'manager' }).then(() => {
                this.$message.success('删除成功')
                this.updateState = true
                this.getGroupMemerListApi()
            })
        },
        getGroupMemerListApi() {
            getGroupMemerList({ groupIds: [this.activeItem.id], type: 'manager' }).then((res) => {
                this.adminList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.select {
    display: flex;
    .el-select {
        flex-grow: 1;
    }
}
.admin {
    .el-tag {
        margin: 0 8px 8px 0;
    }
}
</style>
