<template>
    <div>
        <el-drawer :visible.sync="isShow" title="组织管理" size="720px" :before-close="onClose" @opened="onOpened">
            <div>
                <el-button plain @click="onShowEditGroup(null)" type="primary">新增组织</el-button>
            </div>
            <br />
            <el-table :data="tableData" stripe border>
                <el-table-column header-align="center" label="名称" prop="name" />
                <el-table-column header-align="center" label="管理员" prop="name">
                    <template slot-scope="scope">
                        <div class="admin" v-if="scope.row.admin">
                            <StaffName v-for="i in scope.row.admin" :key="i.id" :staff="i.staff" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column header-align="center" label="消息通知">
                    <template slot-scope="scope">
                        <div v-if="scope.row.botInfo">
                            <el-tag v-if="scope.row.botInfo.trade" effect="plain">订单通知</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="排序" width="80" align="center" prop="seq" />
                <el-table-column label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <div class="mini_link">
                            <el-link type="primary" @click="onShowEditGroup(scope.row)">编辑组织</el-link>
                            <el-popconfirm title="确定删除吗？" @confirm="onDelGroup(scope.row)">
                                <el-link slot="reference" type="danger">删除组织</el-link>
                            </el-popconfirm>
                            <el-link type="info" @click="onShowEditGroupAdmin(scope.row)">编辑管理员</el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>
        <EditGroup :isShow="isShowEditGroup" :activeItem="activeItem" @onClose="onCloseEditGroup" />
        <EditGroupAdmin :isShow="isShowEditGroupAdmin" :activeItem="activeItem" @onClose="onCloseEditGroupAdmin" />
    </div>
</template>
<script>
import EditGroup from './EditGroup.vue'
import EditGroupAdmin from './EditGroupAdmin.vue'
import { getStaffGroupList, delStaffGroup, getGroupMemerList } from '@/js/api/staffApi.js'

export default {
    props: ['isShow'],
    components: { EditGroup, EditGroupAdmin },
    data() {
        return {
            tableData: [],

            activeItem: null,
            isShowEditGroup: false,
            isShowEditGroupAdmin: false,
        }
    },
    methods: {
        onOpened() {
            this.updateState = false
            this.getStaffGroupListApi()
        },
        onClose() {
            this.$emit('onClose', this.updateState)
        },

        onDelGroup(item) {
            delStaffGroup({ groupId: item.id }).then(() => {
                this.$message.success('删除成功')
                this.getStaffGroupListApi()
                this.updateState = true
            })
        },

        onShowEditGroup(item) {
            this.activeItem = item
            this.isShowEditGroup = true
        },
        onCloseEditGroup(state) {
            this.activeItem = null
            this.isShowEditGroup = false
            if (state) {
                this.updateState = true
                this.getStaffGroupListApi()
            }
        },
        // 编辑管理员
        onShowEditGroupAdmin(item) {
            this.activeItem = item
            this.isShowEditGroupAdmin = true
        },
        onCloseEditGroupAdmin(state) {
            this.activeItem = null
            this.isShowEditGroupAdmin = false
            if (state) this.getStaffGroupListApi()
        },

        async getStaffGroupListApi() {
            const res1 = await getStaffGroupList({ orderBy: '+seq' })
            if (res1.data.length !== 0) {
                res1.data.forEach((i) => {
                    console.log(i)
                    if (i.botKey) {
                        i.botInfo = JSON.parse(i.botKey)
                    }
                })
                const _groupIds = res1.data.map((i) => i.id)
                const res2 = await getGroupMemerList({ groupIds: _groupIds, type: 'manager' })
                const _map = {}
                res2.data.forEach((i) => {
                    if (!_map[i.groupId]) {
                        _map[i.groupId] = []
                    }
                    _map[i.groupId].push(i)
                })
                res1.data.forEach((i) => {
                    i.admin = _map[i.id]
                })
            }

            this.tableData = res1.data
        },
    },
}
</script>
<style lang="less" scoped>
.admin {
    span {
        margin: 0 8px 8px 0;
    }
}
</style>
